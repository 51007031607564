<script lang="ts" setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'

import { useCollection } from 'vuefire'
import { collection, addDoc } from 'firebase/firestore'
import { englishCollections } from '/assets/collections-en'
const db = useFirestore()
const user = useCurrentUser()
const collections = useCollection(collection(db, 'collections'))
const collectionsRef = collection(db, 'collections')
function addCollection(data) {
    addDoc(collectionsRef, data)
}
</script>

<template>
 <div class="flex flex-col h-full sm:flex-row  overflow-y-auto" style="height:calc(100vh - 10px);">
            <main
                class="p-2 sm:p-4 md:p-8 sm:pt-2 md:pt-4 body-large pb-2 h-full text-text w-full sm:w-1/2"
                style="line-height:1.7;"
           >
        <h1 class="landing w-3/4 sm:w-full">A curated collection of high-quality humanities books.</h1>
         <br/>
            <h2 class="text-black"><div class="h2 font-medium text-primary mb-2.5">Our mission</div></h2>
            The Modest Inquisitor aims to preserve literary works that are <em class="not-italic bg-accent text-black">personal, rational, specific, and systematic</em>. Every book in this collection has a steadfast premise, and books are grouped by premise so that you can explore different in-depth answers given in response. For instance, explore how 
                <my-nuxt-link class="underline" to="/how-can-moral-consciousness-be-defined-outside-of-religion/the-rebel">
                morality was seen without religious guidance,
                </my-nuxt-link> or see
                <my-nuxt-link class="underline" to="/how-has-media-culture-impacted-modern-thought/amusing-ourselves-to-death">
                the influence of media culture on today's thought.
            </my-nuxt-link>
        
        <div class="mt-2.5">
            <h2 class="h2 font-medium text-primary mb-2.5">Wisdom comes with age</h2>
                Most books in this collection are classics from the 20th century. Our collection includes lesser known works from the best thinkers - from <my-nuxt-link class="underline" to="/how-can-art-and-aesthetics-be-defined/what-is-art">Leo Tolstoy's definition of art that excludes his own works</my-nuxt-link>,
                 <my-nuxt-link class="underline" to="/how-can-art-and-aesthetics-be-defined/what-is-art">Bertrand Russell's take on happiness</my-nuxt-link>, to <my-nuxt-link class="underline" to="/how-has-media-culture-impacted-modern-thought/regarding-the-pain-of-others">Susan Sontag's dissection of the impacts of her own craft - photography</my-nuxt-link>.
                 Nothing here is right or wrong, but everything is thought-provoking. In a world where information abounds but knowledge is rare, none of these books will lead you astray.

            <br/>
            <br/>
            <h2 class="h2 font-medium text-primary mb-2.5">A living collection for you and by you</h2>
            The Modest Inquisitor was launched with 64 books, and collects more books from the community daily. What books do you want to preserve for generations to come?
            <my-nuxt-link to="/search">
                <landing-button class="mt-4 pb-8">Discover</landing-button>
            </my-nuxt-link>
      
        </div>
    </main>
     <div class="hidden sticky top-0 w-1/2 h-screen sm:block flex-grow">
                <div class="pattern"></div>
                <div class="absolute p-2 sm:p-4 md:p-8 right-4 top-4 bg-white rounded-2xl shadow-md">recent activity</div>
            </div>
    </div>
</template>
