<script setup lang="ts"></script>

<template>
    <div class="overflow-hidden">
        <div class="landing-button">
            <div><slot></slot></div>
            <div><slot></slot></div>
        </div>
    </div>
</template>

<style scoped>
.landing-button {
    font: 600 1rem/1.5rem 'Jost';
    cursor: pointer;
    position: relative;
    height: 50px;
    width: 250px;
    transform-style: preserve-3d;
    transition: transform 300ms ease-in-out;
    transform: translateZ(-25px);
}

.landing-button:hover {
    transform: rotateX(90deg) translateY(25px);
}

.landing-button div {
    position: absolute;
    backface-visibility: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.landing-button div:first-child {
    @apply bg-accent text-black;
    transform: translateZ(25px);
}
.landing-button div:last-child {
    @apply bg-primary text-white;
    transform: rotateX(-90deg) translateZ(25px);
}
</style>
